/*
 * @Author: Alan
 * @Date: 2022-06-07 07:47:26
 * @LastEditors: Alan
 * @LastEditTime: 2022-09-18 19:23:29
 * @Description:游戏标签
 * @FilePath: \Mobile\src\components\Games\Tag.js
 */
import React, { Component } from "react";
import classNames from "classnames";
class Tag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasMore: true,
        };
    }

    componentDidMount() {}

    /**
     * 游戏标签
     * @param {*} item 游戏详情
     * @returns 
     * 需要注意Casino 和 Slot 都有TG游戏
     * 这里标签字要用 item.providerFlag，防止API返空多用item.provider
     */
    ChangeTag = (tag, gameType = "")=> {
		console.log(tag, gameType, "tag.js");
        switch (tag) {
			case 'SLS':
				return <span style={{ backgroundColor: '#00A6FF' }}>SLC</span>;
				break;
			case 'PGS':
				return <span style={{ backgroundColor: '#12B2A1' }}>PG</span>;
			case 'MGSQF':
				return <span style={{ backgroundColor: '#2CCB8E' }}>MG</span>;
			case 'IMOPT':
				return <span style={{ backgroundColor: '#2C3F7E' }}>PT</span>;
			case 'TG_SLOT':
				return <span style={{ backgroundColor: '#F49239' }}>PP</span>;
			case 'IMONET':
				return <span style={{ backgroundColor: '#FFFFFF', color: '#46BF00' }}>NET</span>;
			case 'SWF':
				return <span style={{ backgroundColor: '#6978DF' }}>SW</span>;
			case 'CQG':
				return <span style={{ backgroundColor: '#FFFFFF', color: '#FF7700' }}>CG9</span>;
			case 'SPG':
				return <span style={{ backgroundColor: '#F30000' }}>SG</span>;
			case 'BSG':
				return <span style={{ backgroundColor: '#000000' }}>BSG</span>;
			case 'FISHING':
				return <span style={{ backgroundColor: '#00A6FF' }}>FISHING</span>;
			case 'YBF':
				return <span style={{ backgroundColor: '#E4D3B2', color: '#3D3C39' }}>ZUI</span>;
			case 'TG':
				switch (gameType) {
					case 'Slot':
						return <span style={{ backgroundColor: '#F49239' }}>PP</span>;
					case 'LiveCasino':
					default:
						return <span style={{ backgroundColor: '#FFFFFF', color: '#F08B08' }}>PP</span>;
				}
                break;
			/* 棋牌 */
			case 'YBP':
				return <span style={{ backgroundColor: "#EAF4FC", color: "#00B1FF" }}>ANG</span>;
			case 'JBP':
				return <span style={{ backgroundColor: "#E4D3B2", color: "#3D3C39" }}>JBP</span>;
			case 'KYS':
				return <span style={{ backgroundColor: '#E45940' }}>KYS</span>;
			/* 真人娱乐 */
			case 'YBL':
				return <span style={{ backgroundColor: '#B14221' }}>ZUI</span>;
			case 'EVO':
				return <span style={{ backgroundColor: '#679DB9' }}>EVO</span>;
			case 'BGG':
				return <span style={{ backgroundColor: '#311EB9' }}>BG</span>;
			case 'AGL':
				return <span style={{ backgroundColor: '#F98436' }}>AG</span>;
			case 'GPI':
				return <span style={{ backgroundColor: '#1495DA' }}>FUN</span>;
			case 'NLE':
				return <span style={{ backgroundColor: "#FFFFFF", color: "#14594E" }}>N2</span>;
			case 'EBT':
				return <span style={{ backgroundColor: '#2E67B1' }}>EBT</span>;
			case 'ABT':
				return <span style={{ backgroundColor: "#212121", color: "#D1C5AD" }}>ABT</span>;
			case 'VTG':
				return <span style={{ backgroundColor: '#009A23' }}>VTG</span>;
			case 'SPR':
				return <span style={{ backgroundColor: '#E5073A' }}>SPR</span>;
			case 'WEC':
				return <span style={{ backgroundColor: '#A88241' }}>WE</span>;
			case 'EVORT':
				return <span style={{ backgroundColor: '#D9E2DB', color: '#FF0000' }}>RT</span>;
			case 'EVOBT':
				return <span style={{ backgroundColor: '#171717', color: '#FF0000' }}>BTG</span>;
			case 'HSW':
				return <span style={{ backgroundColor: '#5FB174' }}>HSW</span>;
			case 'FCG':
				return <span style={{ backgroundColor: '#2828B2', color: '#FF8519' }}>FCG</span>;
			case 'KPK':
				return <span style={{ backgroundColor: '#047B6C ' }}>KPk</span>;
			case 'TGP':
				return <span style={{ backgroundColor: '#F09A01 ' }}>TGP</span>;
			case 'DGG':
				return <span style={{ backgroundColor: '#EBEBEB', color: '#9F8247' }}>DG</span>;
			case 'GLX':
				return <span style={{ backgroundColor: '#0068FF' }}>GLX</span>;
			case 'GPK':
				return <span style={{ backgroundColor: '#1495DA' }}>FUN</span>;
			case 'SGW':
				return <span style={{ backgroundColor: '#4923D7' }}>SGW</span>;
			case 'TCG':
				return <span style={{ backgroundColor: '#A40000' }}>TC</span>;
			case 'SLS':
				return <span style={{ backgroundColor: '#44B1D5' }}>SLC</span>;
			case 'WMC':
				return <span style={{ backgroundColor: '#0E1544' }}>WMC</span>;
			case 'SXY':
				return <span style={{ backgroundColor: '#FD3684' }}>SXY</span>;
			case 'MGP':
				return <span style={{ backgroundColor: '#2ACA8E' }}>MG</span>;
			case 'JIF':
				return <span style={{ backgroundColor: '#FFFFFF', color: '#2230FF' }}>JIF</span>;
			case 'JIR':
				return <span style={{ backgroundColor: '#2230FF', color: '#FFFFFF' }}>JIR</span>;
			case 'BNG':
				return <span style={{ backgroundColor: '#19E4A4' }}>BNG</span>;
			case 'EVP':
				return <span style={{ backgroundColor: '#1E5F95' }}>EVP</span>;
			case 'HBN':
				return <span style={{ backgroundColor: '#FB5D42' }}>HBN</span>;
			case 'PNG':
				return <span style={{ backgroundColor: '#0182C3' }}>PNG</span>;
			case 'CG9':
				return <span style={{ backgroundColor: '#FFFFFF', color: '#FF7700' }}>CG9</span>;
			default:
				return <span style={{ backgroundColor: '#00A6FF' }}>{tag}</span>;
		}
        // const providerStyles = {
        //     SPR: { backgroundColor: "#E5073A" },
        //     EVO: { backgroundColor: "#679DB9" },
        //     WEC: { backgroundColor: "#A88241" },
        //     GPI: { backgroundColor: "#00A6FF" },
        //     SXY: { backgroundColor: "#FD3684" },
        //     AGL: { backgroundColor: "#F98436" },
        //     WMC: { backgroundColor: "#0E1544" },
        //     DGG: { backgroundColor: "#2D2922", color: "#AC975D" },
        //     TG_LiveCasino: { backgroundColor: "#fff", color: "#F08B08" },
        //     TG_Slot: { backgroundColor: "#F49239" },
        //     JIF: { backgroundColor: "#fff", color: "#2230FF" },
        //     HSW: { backgroundColor: "#5FB174" },
        //     PGS: { backgroundColor: "#45E4D3" },
        //     JIR: { backgroundColor: "#2230FF" },
        //     MGP: { backgroundColor: "#2CCB8E" },
        //     BNG: { backgroundColor: "#19E4A4" },
        //     CQG: { backgroundColor: "#FFFFFF", color: "#FF7700" },
        //     SWF: { backgroundColor: "#6978DF" },
        //     IMOPT: { backgroundColor: "#2C3F7E" },
        //     BSG: { backgroundColor: "#000000" },
        //     PNG: { backgroundColor: "#0182C3" },
        //     SPG: { backgroundColor: "#F30000" },
        //     EVP: { backgroundColor: "#1E5F95" },
        //     HBN: { backgroundColor: "#FB5D42" },
        //     EVOBT: { backgroundColor: "#000000", color: "#FF0916" },
        //     EVORT: { backgroundColor: "#D9E2DB", color: "#FF0916" },
        //     FCG: { backgroundColor: "#2828B2", color: "#FF8519" },
        //     KPK: { backgroundColor: "#047B6C" },
        //     TGP: { backgroundColor: "#F09A01" },
        //     TCG: { backgroundColor: "#2F9D3C" },
        //     SGW: { backgroundColor: "#F49239" },
        //     SLC: { backgroundColor: "#0066FF" },
        //     GPK: { backgroundColor: "#00A6FF" },
        //     default: { backgroundColor: "#00A6FF" }
        // };
    
        // if (item?.provider === 'TG') {
        //     const style = item.gameCatCode === 'LiveCasino' 
        //         ? providerStyles.TG_LiveCasino 
        //         : item.gameCatCode === 'Slot' 
        //             ? providerStyles.TG_Slot 
        //             : providerStyles.default;
        //     return <span style={style}>{item.providerFlag}</span>;
        // }
    
        // const style = providerStyles[item?.provider] || providerStyles.default;
        // return <span style={style}>{item?.providerFlag || item?.provider}</span>;
    }
    
    render() {
        const { item, position } = this.props;
        return (
            <div
                style={{ position: position ? position : "absolute" }}
                className={classNames({
                    DefaultTag: true,
                })}
            >
                {this.ChangeTag(item?.provider, item?.gameCatCode)}
            </div>
        );
    }
}
export default Tag;
